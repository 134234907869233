import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Use StyledEngineProvider implementation from "@mui/material/styles" and add property insertionPoint
const StyledEngineProvider = ({ injectFirst, insertionPoint, children }) => {
  const cache = createCache({ key: "css", prepend: true, container: insertionPoint });

  return injectFirst || insertionPoint ? <CacheProvider value={cache}>{children}</CacheProvider> : children;
};

export const withMuiTheme = (muiTheme, insertionPoint) => (WrappedComponent) => function (props) {
  return (
    <StyledEngineProvider injectFirst={insertionPoint} insertionPoint={insertionPoint}>
      <ThemeProvider theme={muiTheme}>
        <WrappedComponent {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
