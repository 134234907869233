import { forwardRef, useMemo } from "react";
import getCSRFToken from "../../utils/getCSRFToken";

const Form = forwardRef(({
  component: FormComponent,
  children,
  method,
  ...props
}, ref) => {
  const authenticityTokenValue = useMemo(() => getCSRFToken() || "", []);

  const { formMethod, hiddenInputMethod } = useMemo(() => {
    const methodNormalized = method.toLowerCase();

    return {
      formMethod: getFormMethod(methodNormalized),
      hiddenInputMethod: getHiddenInputMethod(methodNormalized),
    };
  }, [method]);

  return (
    <FormComponent method={formMethod} noValidate ref={ref} {...props}>
      <input
        name="authenticity_token"
        type="hidden"
        value={authenticityTokenValue}
      />
      {hiddenInputMethod && (
        <input name="_method" type="hidden" value={hiddenInputMethod} />
      )}
      {children}
    </FormComponent>
  );
});

const getFormMethod = (method) => (method === "get" ? "get" : "post");

const getHiddenInputMethod = (method) => {
  if (["get", "post"].includes(method)) {
    return undefined;
  }
  return method;
};

Form.defaultProps = {
  method: "get",
  component: "form",
};

export default Form;
