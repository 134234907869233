import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Card from "components/payments/Card";
import Layout, { LayoutHeader, LayoutContent, LayoutActions } from "components/Layout";
import Header from "components/Header";
import Container from "components/Container";
import FormikForm from "components/FormikControls/FormikForm";
import Form from "components/Form";
import PageNote from "components/PageNote";
import { useStepConfig } from "hooks/useStepConfig";
import getEnv from "utils/getEnv";
import ActionButton from "./ActionButton";
import ActionButtonWide from "./ActionButtonWide";
import t from "./translate";
import styles from "./styles.module.scss";

const PageRenderer = ({ config }) => {
  const {
    actions,
    title,
    subtitle,
    icon,
    alert,
    content,
    card,
    buttonPrimary,
    buttonSecondary,
    buttonTertiary,
    requiredCopy,
    note,
    form,
    layout = {},
    logo,
    header = {},
  } = config;
  const { components: { Header: headerConfig }, showHeader } = useTheme();
  const { customAlert } = useStepConfig();
  const formLayoutProps = getFormLayoutProps(form);
  const ActionButtonComponent = buttonPrimary && buttonSecondary ? ActionButton : ActionButtonWide;
  const logoDescription = `Logo of ${getEnv("displayName")}`;
  const logoUrl = headerConfig?.showLogo && getEnv("logoUrl", false);
  const logoutPath = getEnv("logoutPath");
  const showEmojisToPatients = getEnv("showEmojisToPatients", true);
  const { columnConstraints, ...layoutProps } = layout;
  const alertsArray = getAlertsArray(alert, customAlert);

  return (
    <Layout
      {...layoutProps}
      {...formLayoutProps}
    >
      {
        showHeader && (
          <Header
            enableExit={headerConfig?.enableExit}
            enableLogout={headerConfig?.enableLogout}
            hideLanguageSelector={!headerConfig?.showLanguageSelector}
            logoDescription={logoDescription}
            logoUrl={logoUrl}
            logoutPath={logoutPath}
            {...header}
          />
        )
      }
      {logo && (
        <Container pt={2} textAlign="center">
          <img alt={logo.alt} className={styles.logo} src={logo.src} />
        </Container>
      )}

      <LayoutHeader
        alerts={alertsArray}
        classes={{
          title: title?.variant === "secondary" ? styles.titleVariantSecondary : undefined,
        }}
        columnConstraints={columnConstraints}
        icon={showEmojisToPatients && icon}
        maintainTopSpacingWhenNoIcon={layout.maintainTopSpacingWhenNoIcon}
        subtitle={subtitle}
        title={title?.text ? title.text : title?.children}
      >
        {title?.text ? title?.children : null}
      </LayoutHeader>

      <LayoutContent columnConstraints={columnConstraints} {...content} />

      <LayoutActions legend={requiredCopy && t("required_copy")} p={2}>
        {note && note.placement !== "bottom" && (
          <Box mb={2} textAlign={note.align}>
            <PageNote component={note.component} light={note.light} small>{note.children}</PageNote>
          </Box>
        )}

        {card && <Card>{card.children}</Card>}

        <Box display="flex" justifyContent="center">
          {buttonSecondary && <ActionButtonComponent {...buttonSecondary} />}
          {buttonPrimary && <ActionButtonComponent {...buttonPrimary} type={form ? "submit" : undefined} />}
        </Box>

        {actions && (
          <Box>
            {actions.children}
          </Box>
        )}

        {buttonTertiary && (
          <Box display="flex" justifyContent="center" mt={2}>
            <ActionButtonWide {...buttonTertiary} sx={{ ...!buttonTertiary.variant && { height: "2rem" } }} />
          </Box>
        )}

        {note && note.placement === "bottom" && (
          <Box mt={2} textAlign={note.align}>
            <PageNote component={note.component} light={note.light} small>{note.children}</PageNote>
          </Box>
        )}
      </LayoutActions>
    </Layout>
  );
};

export default PageRenderer;

const getFormLayoutProps = (formConfigItem) => {
  if (!formConfigItem) return {};

  const { formik, ...rest } = formConfigItem;

  return { component: formik ? FormikForm : Form, ...rest };
};

const getAlertsArray = (alertProps, customAlert) => {
  let alerts = [];
  if (alertProps?.alerts) {
    alerts = alertProps?.alerts;
  }

  if (alertProps?.children) {
    alerts = [alertProps];
  }

  if (customAlert?.text) {
    const existingAlert = alerts.find((alert) => alert.children === customAlert.text && alert.severity === customAlert.type);
    if (!existingAlert) {
      alerts.push({ children: customAlert.text, severity: customAlert.type });
    }
  }

  return alerts;
};
