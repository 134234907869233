import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

export const DEFAULT_COLUMN_CONSTRAINTS = "default";

const ColumnConstraints = ({ children, constraints, ...rest }) => {
  const { defaultColumnConstraints } = useTheme();

  if (!constraints) {
    return children || null;
  }

  const columnConstraints = constraints === DEFAULT_COLUMN_CONSTRAINTS ? defaultColumnConstraints : constraints;

  return (
    <Grid container justifyContent="center">
      <Grid item {...columnConstraints} {...rest}>{children}</Grid>
    </Grid>
  );
};

export default ColumnConstraints;
