import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import useTranslation from "hooks/useTranslation";
import LanguageSelector from "./LanguageSelector";
import LogOut, { AUTOLOGOUT_INITIAL_VALUE_IN_SECONDS } from "./LogOut";
import styles from "./styles.module.scss";

export const HEADER_HEIGHT_IN_PX = 80;

const Header = ({
  autologoutTimeoutInSeconds,
  enableExit,
  enableLogout,
  hideLanguageSelector,
  hideLogoutButton,
  logoDescription,
  logoUrl,
  logoutPath,
  skipReload,
}) => {
  const t = useTranslation("components.header");

  const handleExit = () => {
    if (logoutPath) {
      window.location.href = logoutPath;
    }
  };

  return (
    <Box
      alignItems="center"
      className={styles.root}
      display="flex"
      sx={{ borderBottom: 1, borderColor: "text.primary", height: `${HEADER_HEIGHT_IN_PX}px`, p: 2 }}
    >
      <Grid alignItems="center" container>
        <Grid item xs={6}>
          {logoUrl && (
            <Box alignItems="center" display="flex" position="relative">
              <img alt={logoDescription} className={styles.logoImage} src={logoUrl} />
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box alignItems="center" display="flex" justifyContent="flex-end">
            {!hideLanguageSelector && <LanguageSelector skipReload={skipReload} />}
            {enableLogout && (
              <Box sx={{ marginLeft: 1 }}>
                <LogOut
                  autologoutTimeoutInSeconds={autologoutTimeoutInSeconds}
                  hidden={hideLogoutButton}
                  path={logoutPath}
                />
              </Box>
            )}
            {enableExit && (
              <Button
                aria-label={t("exit")}
                endIcon={<CloseIcon />}
                onClick={handleExit}
                variant="text"
              >
                {t("exit")}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Header.propTypes = {
  autologoutTimeoutInSeconds: PropTypes.number,
  enableExit: PropTypes.bool,
  enableLogout: PropTypes.bool,
  hideLanguageSelector: PropTypes.bool,
  hideLogoutButton: PropTypes.bool,
  logoDescription: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  logoutPath: PropTypes.string,
  skipReload: PropTypes.bool,
};

Header.defaultProps = {
  autologoutTimeoutInSeconds: AUTOLOGOUT_INITIAL_VALUE_IN_SECONDS,
  enableExit: false,
  enableLogout: false,
  hideLanguageSelector: false,
  hideLogoutButton: false,
  logoutPath: undefined,
  logoUrl: undefined,
  skipReload: false,
};

export default Header;
